import { __decorate } from "tslib";
// Watch
import { Component, Vue, Watch } from 'vue-property-decorator';
import { reposicaoStore, mainStore, turmaStore, cursoStore, livroStore, salaStore, turmaAtividadeStore, atividadeStore, turmaAlunoAtividadeStore, } from '@/store';
import _ from 'lodash';
import { QrcodeStream } from 'vue-qrcode-reader';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => {
        if (typeof value === 'number' && value >= 0) {
            return true;
        }
        return !!value;
    },
});
let Professor = class Professor extends Vue {
    constructor() {
        super(...arguments);
        this.dataAulasLecionadas = null;
        this.loading = true;
        this.dialog = false;
        this.selectedAtividadesLecionadas = [];
        this.turmaAlunos = [];
        this.turmaAtividade = [];
        this.requisicaoObservacao = '';
        this.requisicaoTipo = 0;
        this.selectedLivro = {};
        this.selectedAtividade = null;
        this.selectedSala = null;
        this.atividades = [];
        this.reposicoesItems = [];
        this.reposicoesAlunosItems = [];
        this.professorLoading = false;
        this.homeworksPendentes = null;
        this.turmasHomeworksPendentes = [];
        this.turmaAlunoAtividadesPendentes = [];
        this.expandedHomeworkLoading = false;
        this.turmaAtividades = [];
        this.homeworksHeaders = [
            {
                text: 'Aluno',
                sortable: true,
                align: 'left',
                value: 'Aluno.aluno_nome',
            },
            {
                text: 'Turma',
                sortable: true,
                align: 'left',
                value: 'Turma.codigo_referencia',
            },
            {
                text: 'Horários',
                sortable: true,
                align: 'left',
                value: 'horarios',
            },
            {
                text: 'Sala',
                sortable: true,
                align: 'left',
                value: 'Sala.nome',
            },
            {
                text: 'Atividade',
                sortable: true,
                align: 'left',
                value: 'Atividade.descricao',
            },
            {
                text: 'Nota',
                align: 'left',
                value: 'nota',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.turmas = [];
        this.reforcoItems = [];
        this.reforcoAlunosItems = [];
        this.headersTurmasVigentes = [
            {
                text: 'Livro',
                sortable: true,
                value: 'livro',
            },
            {
                text: 'Cronograma',
                sortable: true,
                value: 'cronograma',
            },
            {
                text: 'Sala',
                sortable: true,
                value: 'sala',
            },
            // {
            //   text: 'Início',
            //   sortable: true,
            //   value: 'incio',
            // },
            { text: '', value: 'data-table-expand' },
        ];
        this.headersReposicoes = [
            {
                text: 'Horário',
                sortable: true,
                value: 'data_hora',
            },
            {
                text: 'Sala',
                sortable: true,
                value: 'sala.nome',
            },
            {
                text: 'Professor',
                sortable: true,
                value: 'professor.full_name',
            },
            {
                text: 'Atividade',
                sortable: true,
                value: 'atividade.descricao',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'status',
            },
            // {
            //   text: 'Ações',
            //   value: 'actions',
            // },
            {
                text: '',
                value: 'chevron',
            },
        ];
        this.headersReposicoesExpanded = [
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno.aluno_nome',
            },
            {
                text: 'Presença',
                sortable: true,
                value: 'presenca',
            },
            {
                text: 'Nota',
                sortable: true,
                value: 'notas',
            },
        ];
        this.headersReforcoExpanded = [
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno.aluno_nome',
            },
            {
                text: 'Presença',
                sortable: true,
                value: 'presenca',
            },
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get notasConceito() {
        if (this.userProfile.company.escola_id === 1) {
            return ['Ótimo', 'Bom', 'Regular', 'Insatisfatório'];
        }
        return ['Excelente', 'Bom', 'Aprimorar', 'Observar'];
    }
    get livros() {
        return livroStore.livros;
    }
    get salas() {
        return salaStore.salas;
    }
    get cursos() {
        return cursoStore.cursos;
    }
    get aulaAtividadeTitulo() {
        if (this.turmaAtividade) {
            return this.turmaAtividade.map((item) => item.atividade).join(', ');
        }
        return 'Nenhuma atividade pendente';
    }
    get atividadeLecionadaOptions() {
        const arr = this.turmaAtividade.map((item) => item.atividade_id);
        let minNum = null;
        let maxNum = null;
        arr.forEach((item) => {
            if (!minNum && !maxNum) {
                minNum = item;
                maxNum = item;
                return;
            }
            if (item > maxNum) {
                maxNum = item;
            }
            if (item < minNum) {
                minNum = item;
            }
        });
        const atividadesOptionsIds = [minNum - 1, maxNum + 1, maxNum + 2]
            .concat(arr)
            .sort();
        const atividadeOptions = [];
        atividadesOptionsIds.forEach((item) => {
            const value = _.find(this.atividades, (atv) => atv.id === item);
            if (value) {
                atividadeOptions.push(value);
            }
        });
        return atividadeOptions;
    }
    get tipoAula() {
        const tipos = [];
        if (!!_.find(this.turmaAtividade, (item) => item.atividade_info.avaliacao)) {
            tipos.push('avaliacao');
        }
        if (!!_.find(this.turmaAtividade, (item) => item.atividade_info.homework)) {
            tipos.push('homework');
        }
        if (!!_.find(this.turmaAtividade, (item) => item.atividade_info.revisao)) {
            tipos.push('revisao');
        }
        return tipos;
    }
    get turmasVigentesItems() {
        const diaDaSemanaAtual = new Date().getDay() + 1;
        const response = [];
        _.forEach(this.turmas, (turma) => {
            // let temHoje = false;
            // _.forEach(turma.cronograma_horario, (cronograma) => {
            //   if (+cronograma.dia_semana_index === diaDaSemanaAtual) {
            //     temHoje = true;
            //   }
            // });
            // if (temHoje) {
            response.push({ ...turma, curso: this.getTurmaCurso(turma) });
            // }
        });
        return response;
    }
    async onChangeSelectedLivro(val, oldVal) {
        if (val) {
            this.atividades = await atividadeStore.getAtividades(val.id);
        }
        else {
            this.atividades = [];
        }
    }
    get headersExpanded() {
        return [
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno_nome',
            },
            {
                text: 'Presença(s)',
                sortable: true,
                value: 'presenca',
            },
            {
                text: 'Nota(s)',
                sortable: true,
                value: 'nota',
            },
            {
                text: 'Livro entregue',
                sortable: false,
                value: 'livro_entrega',
            },
        ];
    }
    async onSendCorrectedHomework(item) {
        const isValid = (await this.$refs.homeworkObserver.refs['nota_' + item.TurmaAlunoAtividade.id].validate()).valid;
        if (!isValid) {
            return;
        }
        const toDeleteIndex = _.findIndex(this.turmasHomeworksPendentes, (val) => val === item);
        await turmaAlunoAtividadeStore.updateHomeworkPendente(item);
        this.turmasHomeworksPendentes.splice(toDeleteIndex, 1);
    }
    async onClickAula(expand, isExpanded, item) {
        this.loading = true;
        expand(!isExpanded);
        if (!isExpanded) {
            // @ts-ignore
            this.turmaAlunos = (await turmaStore.getTurmaAlunosFicha(item.id)).gestao;
            this.turmaAtividade =
                await turmaAtividadeStore.getCurrentDateAtividadeByTurma(item.id);
            this.turmaAlunos.forEach((i) => {
                i.presencas = {};
                i.notas = {};
            });
            this.atividades = await atividadeStore.getAtividades(item.livro_id);
        }
        this.loading = false;
    }
    async onClickReposicoes(expand, isExpanded, item, tipo) {
        this.loading = true;
        expand(!isExpanded);
        if (!isExpanded) {
            switch (tipo) {
                case 0:
                    this.reposicoesAlunosItems = await reposicaoStore.getReposicaoAlunos(item.id);
                    this.reposicoesAlunosItems.forEach((val) => {
                        val.presenca = Boolean(val.status);
                        val.notas = {};
                    });
                    this.loading = false;
                    return;
                case 1:
                    this.reforcoAlunosItems = await reposicaoStore.getReposicaoAlunos(item.id);
                    this.reforcoAlunosItems.forEach((val) => {
                        val.presenca = Boolean(val.status === 7);
                        val.notas = {};
                    });
                    this.loading = false;
                    return;
            }
        }
    }
    async saveTurmaAlunosAtividades() {
        const atividades = [];
        let turmaId = 0;
        _.forEach(this.turmaAlunos, (turmaAluno) => {
            _.forEach(this.selectedAtividadesLecionadas, (selectedAtividade, selectedAtividadeIdx) => {
                const turmaAtividade = this.turmaAtividade[selectedAtividadeIdx];
                const turmaAlunoAtividade = {};
                turmaAlunoAtividade.atividade_id = selectedAtividade.id;
                turmaAlunoAtividade.atividade_data_executada = new Date(this.dataAulasLecionadas).toISOString();
                turmaAlunoAtividade.atividade_descricao = selectedAtividade.descricao;
                turmaAlunoAtividade.atividade_nota = selectedAtividade.homework
                    ? turmaAluno.notas?.homework
                    : null;
                turmaAlunoAtividade.company_id = this.userProfile.company.id;
                turmaAlunoAtividade.atividade_presenca = turmaAluno?.presencas[selectedAtividade.id]
                    ? +turmaAluno?.presencas[selectedAtividade.id]
                    : 0;
                // TODO
                turmaAlunoAtividade.reposicao_agendada = false;
                turmaAlunoAtividade.reposicao_concluida = false;
                turmaAlunoAtividade.reposicao_nao_repor = false;
                //
                turmaAlunoAtividade.turma_aluno_id = turmaAluno.turma_aluno_id;
                // turmaAlunoAtividade.atividade_codigo_referencia =
                //   turmaAtividade.codigo_referencia;
                if (selectedAtividade.avaliacao) {
                    turmaAlunoAtividade.atividade_nota_preparacao =
                        turmaAluno.notas?.preparacao;
                    turmaAlunoAtividade.atividade_nota_fala = turmaAluno.notas?.fala;
                    turmaAlunoAtividade.atividade_nota_leitura =
                        turmaAluno.notas?.leitura;
                    turmaAlunoAtividade.atividade_nota_audicao =
                        turmaAluno.notas?.audicao;
                }
                else {
                    turmaAlunoAtividade.atividade_nota_preparacao = null;
                    turmaAlunoAtividade.atividade_nota_fala = null;
                    turmaAlunoAtividade.atividade_nota_leitura = null;
                    turmaAlunoAtividade.atividade_nota_audicao = null;
                }
                turmaAlunoAtividade.atividade_nota_revisao = selectedAtividade.revisao
                    ? turmaAluno.notas?.revisao
                    : null;
                turmaAlunoAtividade.aluno_id = turmaAluno.aluno_id;
                turmaAlunoAtividade.turma_id = turmaAtividade.turma_id;
                atividades.push(turmaAlunoAtividade);
                turmaId = turmaAtividade.turma_id;
            });
        });
        await turmaAlunoAtividadeStore.createTurmaAlunosAtividades(atividades);
        const updatedTurmaAtividades = _.map(this.selectedAtividadesLecionadas, (item) => {
            return {
                atividade: item.descricao,
                atividade_id: item.id,
                turma_id: turmaId,
            };
        });
        await turmaAtividadeStore.UpdateTurmaAtividades(updatedTurmaAtividades);
    }
    async salvarReposicoesPresenca(item) {
        if (item.tipo === 0) {
            this.reposicoesAlunosItems.forEach((val) => (val.presenca = !!val.presenca));
            await reposicaoStore.updateReposicaoAlunosPresenca(this.reposicoesAlunosItems);
        }
        if (item.tipo === 1) {
            this.reforcoAlunosItems.forEach((val) => (val.presenca = !!val.presenca));
            await reposicaoStore.updateReposicaoAlunosPresenca(this.reforcoAlunosItems);
        }
    }
    getTurmaCurso(turma) {
        return _.find(this.cursos, (curso) => turma.livro.curso_id === curso.id);
    }
    async onDecode(codigo, turmaAlunoId) {
        await turmaStore.setEntregaLivro({
            codigo,
            turmaAlunoId,
        });
    }
    async carregaDados() {
        if (this.userProfile.cargo_id === 1) {
            this.loading = true;
            this.turmaAtividades = await turmaAtividadeStore.getTurmaAtividadeHoje();
            this.turmas = await turmaStore.getTurmasByProfessor();
            await cursoStore.getCursos();
            await livroStore.getLivros();
            await salaStore.getSalas();
            await turmaAtividadeStore.getTurmaAtividadeHoje();
            this.turmasHomeworksPendentes =
                await turmaAlunoAtividadeStore.getTurmaAlunosHomeworksPendentesTurmas();
            this.reforcoItems = await reposicaoStore.getReforcoHojeByProfessor(this.userProfile.id);
            this.reposicoesItems = await reposicaoStore.getReposicaoHojeByProfessor(this.userProfile.id);
            this.loading = false;
        }
    }
    async mounted() {
        await this.carregaDados();
    }
};
__decorate([
    Watch('selectedLivro')
], Professor.prototype, "onChangeSelectedLivro", null);
Professor = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            QrcodeStream,
        },
    })
], Professor);
export default Professor;
